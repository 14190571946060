import React, { useEffect, useState } from "react";
import { CarbonConnect, AutoSyncedSourceTypes } from "carbon-connect";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./Custom.css"; // Import CSS

const Carbon = () => {
    // Get the customer_id from the URL query parameter
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("customer_id");
    const askai_id = new URLSearchParams(search).get("askai_id");
    let entry_Point = new URLSearchParams(search).get("entryPoint");
    const carbon_v2 = new URLSearchParams(search).get("carbon_v2");
    const apiURL =
        carbon_v2 === "yes"
            ? "https://knowledge.myaskai.com"
            : "https://api.carbon.ai";

    const v2 = new URLSearchParams(search).get("v2");
    const v2_tickets = new URLSearchParams(search).get("v2_tickets");
    const knowledge_type = new URLSearchParams(search).get("knowledge_type");
    const redirect_url = new URLSearchParams(search).get("redirect_url");

    // console.log(id, askai_id, entry_Point, redirect_url, v2, "Cv2", carbon_v2, apiURL);

    // Set up enabledIntegrations based on query params
    let enabledIntegrations = [];

    if (v2 === "yes") {
        enabledIntegrations = [
            {
                id: "NOTION",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true,
            },
            {
                id: "GOOGLE_DRIVE",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true,
                fileSyncConfig: {
                    split_rows: true,
                },
            },
            {
                id: "INTERCOM",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true,
            },
            {
                id: "DROPBOX",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
            },
            {
                id: "ONEDRIVE",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
            },
            {
                id: "ZENDESK",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true,
            },
            {
                id: "FRESHDESK",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true,
            },
            {
                id: "CONFLUENCE",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
                syncFilesOnConnection: false,
            },
            {
                id: "SHAREPOINT",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
            },
            {
                id: "SALESFORCE",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
                syncFilesOnConnection: false,
            },
        ];

        // If entryPoint is LOCAL_FILES
        if (entry_Point === "LOCAL_FILES") {
            enabledIntegrations = [
                {
                    id: "LOCAL_FILES",
                    chunkSize: 400,
                    overlapSize: 20,
                    maxFileSize: 30000000,
                    allowMultipleFiles: true,
                    showFilesTab: false,
                    split_rows: true,
                    maxFilesCount: 25,
                    filePickerMode: "BOTH",
                    allowedFileTypes: [
                        { extension: "csv" },
                        { extension: "xlsx" },
                        { extension: "mp4" },
                        { extension: "mp3" },
                        { extension: "txt" },
                        { extension: "pdf" },
                        { extension: "json" },
                        { extension: "docx" },
                        { extension: "pptx" },
                        { extension: "md" },
                    ],
                },
            ];
        }

        // If v2_tickets is yes
        if (v2_tickets === "yes") {
            enabledIntegrations = [
                {
                    id: "INTERCOM",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                    fileSyncConfig: {
                        auto_synced_source_types: [AutoSyncedSourceTypes.CONVERSATION],
                    },
                },
                {
                    id: "ZENDESK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                    fileSyncConfig: {
                        auto_synced_source_types: [AutoSyncedSourceTypes.TICKET],
                    },
                },
            ];
        }

        // Start of knowledge_type-specific config
        if (knowledge_type === "help_centre") {
            enabledIntegrations = [
                {
                    id: "INTERCOM",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                },
                {
                    id: "FRESHDESK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                },
                {
                    id: "ZENDESK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                },
            ];
        }

        if (knowledge_type === "internal") {
            enabledIntegrations = [
                {
                    id: "NOTION",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: true,
                },
                {
                    id: "GOOGLE_DRIVE",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: true,
                    fileSyncConfig: {
                        split_rows: true,
                    },
                },
                {
                    id: "DROPBOX",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                },
                {
                    id: "ONEDRIVE",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                },
                {
                    id: "CONFLUENCE",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                    syncFilesOnConnection: false,
                },
                {
                    id: "SHAREPOINT",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                },
                {
                    id: "SALESFORCE",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                    syncFilesOnConnection: false,
                },
            ];
        }
    } else {
        // Default if v2 !== "yes"
        enabledIntegrations = [
            {
                id: "NOTION",
                chunkSize: 1500,
                overlapSize: 20,
                skipEmbeddingGeneration: true,
                enableAutoSync: true,
            },
            {
                id: "GOOGLE_DRIVE",
                chunkSize: 1500,
                overlapSize: 20,
                skipEmbeddingGeneration: true,
                enableAutoSync: true,
            },
        ];
    }

    entry_Point = entry_Point || "INTEGRATION_LIST";

    // Handle success
    const handleOnSuccess = (data) => {
        console.log("Result of OnSuccess=>", data);
        if (redirect_url != null) {
            window.location.assign(`${redirect_url}?source=${entry_Point}&status=success`);
        }
    };

    // Fetch Carbon access token
    const tokenFetcher = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE}auth/fetch-carbon-token`, {
            params: {
                customer_id: id,
                ...(carbon_v2 === "yes" && { carbon_v2: "yes" }),
            },
            headers: {
                Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
            },
        });
        return response.data;
    };

    // Alert if needed parameters are missing
    useEffect(() => {
        if (id == null) {
            if (askai_id == null) {
                alert("Please provide Customer ID and askai ID in the URL");
            } else {
                alert("Please provide customer ID in the URL");
            }
        } else if (askai_id == null) {
            alert("Please provide askai id in the URL");
        } else {
            setIsInitialized(true); // Set initialized to true when params are valid
        }
    }, [id, askai_id]);

    // Add state to manage initialization
    const [isInitialized, setIsInitialized] = useState(false);

    return (
        <>
            {/* Render only if initialized and both askai_id and id are present */}
            {isInitialized && askai_id != null && id != null ? (
                v2 === "yes" ? (
                    <CarbonConnect
                        orgName="My AskAI"
                        brandIcon="https://7ac07acbae116a5155080d295197d8d6.cdn.bubble.io/f1689768781903x860453087150673900/Logo.png"
                        tokenFetcher={tokenFetcher}
                        tags={
                            v2 === "yes"
                                ? { askai_id: askai_id, version: "v2" }
                                : { askai_id: askai_id }
                        }
                        embeddingModel="OPENAI_ADA_LARGE_1024"
                        maxFileSize={10000000}
                        backButtonText=""
                        theme="light"
                        apiURL={apiURL}
                        splitRows={true}
                        showFilesTab={false}
                        sendDeletionWebhooks={true}
                        enabledIntegrations={enabledIntegrations}
                        allowMultipleFiles={true}
                        onError={(error) => console.log("Data on Error: ", error)}
                        primaryBackgroundColor="#1F3261"
                        primaryTextColor="#FFFFFF"
                        entryPoint={entry_Point}
                        secondaryBackgroundColor="#FFFFFF"
                        incrementalSync={true}
                        secondaryTextColor="#1F3261"
                        loadingIconColor="#1F3261"
                        open={true}
                        alwaysOpen={true}
                        tosURL="https://myaskai.com/terms"
                        privacyPolicyURL="https://myaskai.com/privacy"
                        onSuccess={handleOnSuccess}
                    />
                ) : (
                    <CarbonConnect
                        orgName="My AskAI"
                        brandIcon="https://7ac07acbae116a5155080d295197d8d6.cdn.bubble.io/f1689768781903x860453087150673900/Logo.png"
                        tokenFetcher={tokenFetcher}
                        useCarbonFilePicker={false}
                        tags={
                            v2 === "yes"
                                ? { askai_id: askai_id, version: "v2" }
                                : { askai_id: askai_id }
                        }
                        maxFileSize={10000000}
                        sendDeletionWebhooks={true}
                        incrementalSync={true}
                        apiURL={apiURL}
                        theme="light"
                        enabledIntegrations={enabledIntegrations}
                        entryPoint={entry_Point}
                        onError={(error) => console.log("Data on Error: ", error)}
                        primaryBackgroundColor="#1F3261"
                        primaryTextColor="#FFFFFF"
                        secondaryBackgroundColor="#FFFFFF"
                        secondaryTextColor="#1F3261"
                        open={true}
                        alwaysOpen={true}
                        tosURL="https://myaskai.com/terms"
                        privacyPolicyURL="https://myaskai.com/privacy"
                        onSuccess={handleOnSuccess}
                    />
                )
            ) : (
                // Optionally, you can render a loader or message here
                <div>Loading...</div>
            )}
        </>
    );
};

export default Carbon;